<template>
    <div v-if="registro_open" id="registro">
        <div class="registry-main-container">
            <div class="ticker-and-entries">
                <span class="cerrar-boton" @click="close_registro()">&times;</span>
                <div class="fixed-top">
                    <div style="margin: 20px 0 20px 0; display: flex">
                        <Button id="favorite-field-button" @_emit_method_button="_changeColorFavorites()"
                            :button_icon="button_icon_favorite" :button_name="button_name_favorite"
                            :button_key="button_key_favorite" :button_icon_id="button_icon_id_favorite" />

                        <Button id="cartera-field-button" @_emit_method_button="_changeColorPortfolio()"
                            :button_icon="button_icon_cartera" :button_name="button_name_cartera"
                            :button_key="button_key_cartera" :button_icon_id="button_icon_id_cartera" />

                        <Button id="notas-field-button" @_emit_method_button="_changeColorNotes()"
                            :button_icon="button_icon_notas" :button_name="button_name_notas" :button_key="button_key_notas"
                            :button_icon_id="button_icon_id_notas" />

                        <Button id="alarm-field-button" @_emit_method_button="_changeColorAlarms()"
                            :button_icon="button_icon_alarm" :button_name="button_name_alarm" :button_key="button_key_alarm"
                            :button_icon_id="button_icon_id_alarm" />
                    </div>
                </div>
                <div class="entries-container" v-if="_g_UserFeatures.permissions.stock_registry">
                    <div v-for="(item, index) in data" :key="index">
                        <div v-if="show_favorites_registry">
                            <div v-if="item.type == 'favorite'" class="container-favorite">
                                <p class="registry-date" style="width: 145px;">{{ item.date }}</p>
                                <p class="registry-text">{{ item.text }}</p>
                            </div>
                        </div>
                        <div v-if="show_portfolio_registry">
                            <div v-if="item.type == 'portfolio'" class="container-portfolio">
                                <p class="registry-date">{{ item.date }}</p>
                                <p class="registry-text">{{ item.text }}</p>
                            </div>
                        </div>
                        <div v-if="show_notes_registry">
                            <div v-if="item.type == 'note'" class="container-note">
                                <div style="display: flex">
                                    <p class="registry-date">{{ item.date }}</p>
                                    <span class="material-icons-outlined" style="margin: 8px 6px 0 auto; font-size: 18px"
                                        @click="_deleteNote(item.nid)">delete</span>
                                </div>
                                <p class="registry-text">{{ item.text }}</p>
                            </div>
                        </div>
                        <div v-if="show_alarms_registry">
                            <div v-if="item.type == 'alarm'" class="container-alarm">
                                <p class="registry-date">{{ item.date }}</p>
                                <pre class="registry-text">{{ item.text }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="registry-input-text" v-if="_g_UserFeatures.permissions.stock_registry">
                    <div id="registry-writer">
                        <div class="comment-box">
                            <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValue"
                                :ref="note_input_ref" :input_key_to_textarea_values="input_key_to_textarea_values"
                                :input_placeholder="input_placeholder" />
                            <Button id="comment-button-id" @_emit_method_button="_createNote" :button_name="button_name"
                                :button_key="button_key_registry" :button_icon_permission="true" :button_icon="button_icon"
                                :button_icon_id="button_icon_id" />
                        </div>
                    </div>
                </div>
                <div v-if="!_g_UserFeatures.permissions.stock_registry" class="entries-container"
                    style="text-align: center;">
                    <p>You do not have access to registry.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import Button from "@/components/helpers/Button.vue";
import InputTextarea from "@/components/helpers/InputTextarea.vue";

import { mapGetters } from "vuex";
export default {
    mixins: [APICaller],
    name: "Registro",
    components: {
        Button,
        InputTextarea
    },
    props: {
        registro_open: { type: Boolean },
    },
    data: () => ({
        id_header: "registro-header",
        title_header: "Registro",

        button_name: "",
        button_key_registry: "button_registry",
        button_icon: "done",
        button_icon_id: "icon_button_registry",

        note_input_ref: "ref-note-input",
        input_key_to_textarea_values: "key-to-text-area-values-note-input",
        input_placeholder: "Escriba su nota aqui",


        button_icon_favorite: "button-icon-favorite",
        button_name_favorite: "Favoritos",
        button_key_favorite: "key-favorite-button",
        button_icon_id_favorite: "button-icon-id-favorite",

        button_icon_cartera: "button-icon-cartera",
        button_name_cartera: "Cartera",
        button_key_cartera: "key-cartera-button",
        button_icon_id_cartera: "button-icon-id-cartera",

        button_icon_notas: "button-icon-notas",
        button_name_notas: "Notas",
        button_key_notas: "key-notas-button",
        button_icon_id_notas: "button-icon-id-notas",

        button_icon_alarm: "button-icon-alarm",
        button_name_alarm: "Alarmas",
        button_key_alarm: "key-alert-button",
        button_icon_id_alarm: "button-icon-id-alarm",

        show_favorites_registry: true,
        show_portfolio_registry: true,
        show_notes_registry: true,
        show_alarms_registry: true,
        alarms_in_registry: [],

        data: [],

        new_note: "",
        alarmsDict: {},
        callback_counter: 0,


    }),
    created() {

    },
    watch: {
        _g_SelectedTicker(newVal, oldVal) {
            if (newVal != oldVal) {
                this._getRegistry()
            }
        }
    },
    computed: {
        ...mapGetters(['_g_SelectedTicker', '_g_UserId', '_g_UserFeatures']),
    },
    mounted() {
        let waiting_for_selected_ticker = setInterval(() => {
            if (this._g_SelectedTicker && this._g_SelectedTicker.ticker != undefined) {
                this._getRegistry()
                clearInterval(waiting_for_selected_ticker)
            }
        }, 20)
    },
    methods: {
        _changeColorFavorites: function () {
            this.show_favorites_registry = !this.show_favorites_registry
            if (this.show_favorites_registry) {
                document.getElementById("favorite-field-button").style.backgroundColor = "rgba(227, 155, 15, 0.25)";
                document.getElementById("favorite-field-button").style.border = "1px solid #E39B0F";
            } else {
                document.getElementById("favorite-field-button").style.backgroundColor = "#f3f4f7";
                document.getElementById("favorite-field-button").style.border = "1px solid white";
            }
        },

        _changeColorPortfolio: function () {
            this.show_portfolio_registry = !this.show_portfolio_registry
            if (this.show_portfolio_registry) {
                document.getElementById("cartera-field-button").style.backgroundColor = "rgba(186, 145, 187, 0.25)";
                document.getElementById("cartera-field-button").style.border = "1px solid #BA91BB";
            } else {
                document.getElementById("cartera-field-button").style.backgroundColor = "#f3f4f7";
                document.getElementById("cartera-field-button").style.border = "1px solid white";
            }
        },

        _changeColorNotes: function () {
            this.show_notes_registry = !this.show_notes_registry
            if (this.show_notes_registry) {
                document.getElementById("notas-field-button").style.backgroundColor = "rgba(73, 76, 90, 0.25)";
                document.getElementById("notas-field-button").style.border = "1px solid #20202B";
            } else {
                document.getElementById("notas-field-button").style.backgroundColor = "#f3f4f7";
                document.getElementById("notas-field-button").style.border = "1px solid white";
            }
        },

        _changeColorAlarms: function () {
            this.show_alarms_registry = !this.show_alarms_registry
            if (this.show_alarms_registry) {
                document.getElementById("alarm-field-button").style.backgroundColor = "rgba(31, 122, 160, 0.25)";
                document.getElementById("alarm-field-button").style.border = "1px solid #1F7AA0";
            } else {
                document.getElementById("alarm-field-button").style.backgroundColor = "#f3f4f7";
                document.getElementById("alarm-field-button").style.border = "1px solid white";
            }
        },

        _getInputValue(value) {
            this.new_note = value[0]
        },

        _createNote: function () {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            this._postNote()
        },

        _postNote: function () {
            let params = new URLSearchParams();
            params.append('uid', this._g_UserId);
            params.append('ticker', this._g_SelectedTicker.ticker);
            params.append('title', "")
            params.append('body', this.new_note)

            let success = response => {
                this._getNotes()
            }

            let url = "/api/v1/eurekers-notes/create"

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('261', 'Error en la creación de la nota.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _deleteNote: function (nid) {
            let params = new URLSearchParams();
            params.append('uid', this._g_UserId);
            params.append('nid', nid)

            let success = response => { this._getRegistry() }

            let url = "/api/v1/eurekers-notes/delete"

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('262', 'Error en el borrado de la nota.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _getRegistry: function () {
            let success = response => {
                this.data = []
                let datos = response.data
                datos["favorites"].forEach(element => {
                    if (element.ticker == this._g_SelectedTicker.ticker) {
                        this.data.push({ date: this._timeConverter(element.timestamp), text: this._checkFavoriteAction(element.favorite_action, element.ticker, element.favorite_folder_name), type: "favorite", timestamp: element.timestamp })
                    }
                })
                this.alarms_in_registry = []
                datos["alerts"].forEach(element => {
                    if (element.ticker == this._g_SelectedTicker.ticker) {
                        this.alarms_in_registry.push(element)
                    }
                })
                datos["portfolio"].forEach(element => {
                    if (element.ticker == this._g_SelectedTicker.ticker) {
                        this.data.push({ date: this._timeConverter(element.timestamp), text: this._checkPortfolioAction(element.portfolio_action, element.ticker), type: "portfolio", timestamp: element.timestamp })
                    }
                })
                this._getAlarms()
                this._getNotes()
            }

            let url = "/api/v1/eurekers-historical-registry/get-historical-registry-by-user/user/" + this._g_UserId

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('064', 'Error recuperando el registro de usuario.');

            this._getAPICall(url, successHandler, failureHandler);
        },
        _getNotes: function () {
            let success = response => {
                let notes = response.data
                this.data.forEach((element, index) => {
                    if (element.type == "note") {
                        this.data.splice(index, 1)
                    }
                })
                notes.forEach(element => {
                    if (element.ticker == this._g_SelectedTicker.ticker) {
                        this.data.push({ date: this._timeConverter(element.timestamp), text: element.body, type: "note", nid: element.nid, timestamp: element.timestamp })
                    }
                })
                this.callback_counter++;
                if (this.callback_counter >= 2) {
                    this.data.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
                }
            }

            let url = "/api/v1/eurekers-notes/user/" + this._g_UserId + "/get"

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('065', 'Error recuperando las notas de usuario.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        _getAlarms: function () {
            let params = new URLSearchParams();
            params.append('uid', this._g_UserId);

            let success = response => {
                this.alarmsDict = {}
                let alarms = response.data
                this.data.forEach((element, index) => {
                    if (element.type == "alarm") {
                        this.data.splice(index, 1)
                    }
                })
                this.alarmsDict = this._groupById(alarms)
                this._preprocessAlarms(this.alarmsDict)
            }

            let url = "/api/v1/eurekers-alerts/get-user-alerts"

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('263', 'Error recuperando alarmas.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _timeConverter: function (UNIX_timestamp) {
            if (UNIX_timestamp == 0) {
                return "Fecha no disponible"
            } else {
                let a = new Date(UNIX_timestamp * 1000);
                let year = a.getFullYear();
                let month = a.getMonth() + 1;
                let date = a.getDate();
                let time = date + '/' + month + '/' + year;
                return time;
            }
        },

        _checkPortfolioAction: function (portfolio_action, ticker) {
            const cases = {
                PORTFOLIO_CREATED_OPEN_POSITION: "El ticker " + ticker + " se ha añadido a posiciones abiertas.",
                PORTFOLIO_CLOSED_OPEN_POSITION: "El ticker " + ticker + " se ha cerrado de posiciones abiertas.",
                PORTFOLIO_CREATED_WAITING_VALUE: "El ticker " + ticker + " se ha añadido a valores en espera.",
                PORTFOLIO_REMOVED_WAITING_VALUE: "El ticker " + ticker + " se ha cerrado de valores en espera.",
                PORTFOLIO_REMOVED_VALUE: "El ticker " + ticker + " se ha eliminado de tu cartera."
            }

            const result = cases[portfolio_action]
            return result
        },
        _checkFavoriteAction: function (favorite_action, ticker, folder_name) {
            const cases = {
                FAVORITE_ADDED: "El ticker " + ticker + " se añadió a la carpeta " + folder_name,
                FAVORITE_REMOVED: "El ticker " + ticker + " se eliminó de la carpeta " + folder_name
            }

            const result = cases[favorite_action]
            return result
        },
        _preprocessAlarms: function (diccionario_alarmas) {
            this.alarms_in_registry.forEach(element => {
                if (element.alert_action == "ALERT_CREATED") {
                    this.data.push({ date: this._timeParser(diccionario_alarmas[element.alert_id][0].created), text: this._checkAlertsStatus(element.alert_action, diccionario_alarmas, element.alert_id), type: "alarm", timestamp: element.timestamp })
                } else {
                    this.data.push({ date: this._timeConverter(element.timestamp), text: this._checkAlertsStatus(element.alert_action, diccionario_alarmas, element.alert_id), type: "alarm", timestamp: element.timestamp })
                }
            })
            this.callback_counter++;
            if (this.callback_counter >= 2) {
                this.data.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
            }
        },
        _checkAlertsStatus: function (alert_action, diccionario_alarmas, id) {
            const cases = {
                ALERT_CREATED: "La alarma " + diccionario_alarmas[id][0].alert_field_string + " " + diccionario_alarmas[id][0].alert_mode_string + " " + diccionario_alarmas[id][0].alert_data + " ha sido creada.",
                ALERT_EXECUTED: "La alarma " + diccionario_alarmas[id][0].alert_field_string + " " + diccionario_alarmas[id][0].alert_mode_string + " " + diccionario_alarmas[id][0].alert_data + " ha sido ejecutada."
            }

            const result = cases[alert_action]
            return result
        },
        _groupById: function (elements) {
            const result = {};

            for (const element of elements) {
                if (!result[element.id]) {
                    result[element.id] = [];
                }

                result[element.id].push(element);
            }

            return result;
        },

        _timeParser: function (fecha) {
            var options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            };

            var fecha_obj = new Date(fecha);
            var fecha_formateada = fecha_obj.toLocaleDateString('es-ES', options);
            return fecha_formateada
        },

        close_registro: function(){
          this.$emit('close_registro');
        },
    },
}
</script>

<style lang="scss">
.ticker-name-container {
    background-color: #f3f4f7;
    width: 250px;
    height: 40px;
    margin: auto;
    text-align: center;
}

.ticker-name-container p {
    padding-top: 10px;
}

.container-alarm {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid #42a0c9;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.container-note {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid rgba(73, 76, 90, 0.25);
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.container-portfolio {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid #BA91BB;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.container-favorite {
    margin-left: 5px;
    border: 1px solid lightgrey;
    border-left: 5px solid #E39B0F;
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
}

.comment-box {
    display: flex;
    align-items: center;
    width: 95%;
    margin-left: 10px;
    height: 50%;
}

.comment-box label {
    margin-top: 5px;
    height: 40px !important;
}

.registry-date {
    width: 170px;
    height: 24px;

    /* Subtitle 2 */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.1px;

    /* Tema Claro/02. Primary/900 */

    color: #000010;

    padding: 3px 0 0 5px;
    margin: 0px !important;
}

.registry-text {
    width: 341px;

    /* Caption */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 0.4px;

    /* Tema Claro/02. Primary/900 */

    color: #000010;

    padding: 5px 0 1px 15px;
}

.entries-container {
    overflow-y: scroll;
    height: 250px;
}

.ticker-and-entries {
    display: flex;
    flex-direction: column;
}

.registry-input-text {
    z-index: 100;
    bottom: 0px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    width: 402px;
}

.fixed-top {
    z-index: 100;
    top: 70px;
}

#registro {
    border-radius: 20px;
    background-color: #F3F4F7 !important;
}

.cerrar-boton {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 18px;
    color: #333;
    text-align: right;
    margin-right: 12px;
}
</style>