<template>
  <div id="valores">
    <div v-if="this._g_IsUserLogged" class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-2"></div>
      <div class="mdc-layout-grid__cell--span-8">
        <div>
          <h3 id="stocks-component" class="stocks-title">
            {{ this.stock_name }} ({{ this.stock_company }})
            <img v-if="this.is_news_available" id="stock-img" width="35px" height="35px" />
          </h3>
        </div>
        <div id="chart-options-stock">
          <Button id="grafic-option" @_emit_method_button="_activeChart" :button_name="button_gr"
            :button_key="button_key_chart" />
          <Button id="description-option" class="info-button" @_emit_method_button="_activeDescription"
            :button_name="button_des" :button_key="button_key_des" />
        </div>
        <div v-if="activeChart" id="chart-container" class="mdc-layout-grid__cell--span-10">

          <span class="iframe-container">
            <iframe :src="url_stock" width="98.55%" frameborder="0" id="iframe"
              style="height: 90%;min-height:350px"></iframe>
            <!--<Chart style="height:400px" :symbol="url_stock" />-->
          </span>
          <div v-if="this.is_mobile_device" class="info-container">
            <div class="info-parameter mobile">
              <p><b>Ticker:</b> {{ this.stock_name }}</p>
              <p><b>Compañia:</b> {{ this.stock_company }}</p>
              <p><b>Índice:</b> {{ this.stock_index }}</p>
              <p><b>Sector:</b> {{ this.stock_sector }}</p>
              <p><b>Divisa:</b> {{ this.stock_divisa }}</p>
              <p><b>Valor actual:</b> {{ this.stock_actual_value }}</p>
            </div>
          </div>
          <span v-else class="info-container" style="float:right;">
            <div id="info">
              <div class="info-parameter">
                <p><b>Ticker:</b> {{ this.stock_name }}</p>
                <p><b>Compañia:</b> {{ this.stock_company }}</p>
                <p><b>Índice:</b> {{ this.stock_index }}</p>
                <p><b>Sector:</b> {{ this.stock_sector }}</p>
                <p><b>Divisa:</b> {{ this.stock_divisa }}</p>
                <p><b>Valor actual:</b> {{ this.stock_actual_value }}</p>
              </div>
              <div v-if="this.is_news_available" class="news-title">
                <p><b>Noticias:</b></p>
                <div id="news-container"></div>
              </div>
            </div>
          </span>

        </div>
        <div v-if="activeDescription">
          <p class="description">{{ this.stock_description }}</p>
        </div>

        <div class="table-info">
          <TableCustom :table_header="table_header" :table_body="table_body" :stock="stock" :stocks="[stock]" :chart_url="chart_url" />
        </div>

        <div class="comments" id="comments">
          <p class="comment-count-text text-bold">
            {{ comments.length }} COMENTARIOS
          </p>
          <hr class="first-line" />
          <div v-if="comments.length == 0">
            <p class="no-comment-text">
              Aún no hay comentarios para este ticker
            </p>
          </div>
          <div v-else id="comments-wrapper">
            <div v-for="(comment, index) in comments_page" :key="index">
              <div v-if="comment.new > 0">
                <span class="material-icons-outlined">new_releases</span>
              </div>
              <div id="comment">
                <div style="display:flex;">
                  <div class="user-box">
                    <div v-html="comment.user.picture" id="comment-user"></div>
                  </div>
                  <div id="comment-text">{{ comment.message }}</div>
                </div>
                <div id="comment-rating">
                  <span @click="upComment(comment)" class="comment-icon material-icons">sentiment_satisfied_alt</span>
                  <span @click="downComment(comment)"
                    class="comment-icon material-icons">sentiment_very_dissatisfied</span>
                  <span class="comment-text">Puntos:
                    <span class="text-bold-points">{{
                      comment.user.votes.avg
                    }}</span>
                  </span>
                </div>
                <hr />
              </div>
            </div>
          </div>

          <div class="pagination">
            <button @click="previousPage" :disabled="currentPage === 1">Anterior</button>
            <span> - </span>
            <button v-for="index in Math.min(comments_length, currentPage + 10)" :key="index" @click="goToPage(index)"
              :class="{ active: index === currentPage }">
              {{ index }}
            </button>
            <span> - </span>
            <button @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
          </div>

          <div id="comment-writer">
            <div class="comment-box">
              <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValue"
                :ref="input_comments.ref" :input_key_to_textarea_values="input_comments.input_key_to_textarea_values
                  " :input_placeholder="input_comments.input_placeholder" />
              <Button id="comment-button-id" @_emit_method_button="createComment" :button_name="button_name"
                :button_key="button_key_comments" :button_icon_permission="true" :button_icon="button_icon"
                :button_icon_id="button_icon_id" />
            </div>
          </div>
        </div>
      </div>
    </div>



    <div v-else class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-2"></div>
      <div class="mdc-layout-grid__cell--span-6">
        <div>
          <h3 id="stocks-component" class="stocks-title">
            {{ this.stock_name }} ({{ this.stock_company }})
            <img v-if="this.is_news_available" id="stock-img" width="35px" height="35px" />
          </h3>
        </div>
        <iframe src="https://www.stonicks.com/embed/?c=4E6E82&amp;sc=logaritmic&amp;s="
          style="width: 100%; height: 320px;" frameborder="0" id="iframe"></iframe>
        <div>
          <div v-if="this.is_mobile_device" class="info-parameter">
            <p><b>Ticker:</b> {{ this.stock_name }}</p>
            <p><b>Compañia:</b> {{ this.stock_company }}</p>
            <p><b>Índice:</b> {{ this.stock_index }}</p>
            <p><b>Sector:</b> {{ this.stock_sector }}</p>
            <p><b>Divisa:</b> {{ this.stock_divisa }}</p>
            <p><b>Valor actual:</b> {{ this.stock_actual_value }}</p>
            <br />
          </div>
          <p class="description">{{ this.stock_description }}</p>
        </div>
        <div class="similar-tickers">
          <p class="titulito"><b>Similar tickers: </b></p>
          <div id="similares"></div>
        </div>
      </div>
      <div v-if="!this.is_mobile_device" id="info" class="mdc-layout-grid__cell--span-2">
        <div class="info-parameter" style="margin-top:116px;">
          <p><b>Ticker:</b> {{ this.stock_name }}</p>
          <p><b>Compañia:</b> {{ this.stock_company }}</p>
          <p><b>Índice:</b> {{ this.stock_index }}</p>
          <p><b>Sector:</b> {{ this.stock_sector }}</p>
          <p><b>Divisa:</b> {{ this.stock_divisa }}</p>
          <p><b>Valor actual:</b> {{ this.stock_actual_value }}</p>
        </div>
        <div v-if="this.is_news_available" class="news-title">
          <p><b>Noticias:</b></p>
          <div id="news-container"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters, mapMutations } from "vuex";
import InputTextarea from "@/components/helpers/InputTextarea.vue";
import Button from "@/components/helpers/Button";
import InputComments from "@/components/stockchart/components/configs/input_comments.json";
import TableCustom from "@/components/helpers/Table_custom_valores.vue";
import Workspaces from '@/components/stockchart/components/Workspaces.vue';
import WaitingValues from '@/components/stockchart/components/WaitingValuesAdd.vue';
import Registro from "@/components/stockchart/components/Registro_nota_stock.vue";

export default {
  mixins: [APICaller],
  name: "Valores",
  metaInfo() {
    return {
      title: this.$route.params.ticker + ' (' + this.stock_company + ') ' + this.stock_actual_value + ' | Graficador de Eurekers',
      meta: [
        { name: 'description', content: this.$route.params.ticker + " | Consulta las últimas cotizaciones, historial, noticias y mucho más de " + this.stock_company + " con Eurekers" }
      ]
    }
  },
  components: {
    InputTextarea,
    Button,
    TableCustom,
    Workspaces,
    WaitingValues,
    Registro
  },
  data() {
    return {
      stock: "",
      stock_name: "",
      stock_company: "",
      stock_index: "",
      stock_sector: "",
      stock_divisa: "",
      stock_actual_value: 0,
      stock_description: "",
      stock_icon: "",
      stock_nid: 800,
      symbol: "",
      activeChart: true,
      activeDescription: false,
      comments: [],
      input_comments: InputComments,
      chart_url: "",

      button_name: "",
      button_key_comments: "button_comments",
      button_key_chart: "button_gr",
      button_key_des: "button_des",
      button_icon: "done",
      button_icon_id: "icon_button_comment",
      button_des: "Información",
      button_gr: "Gráfica",
      new_comment: "",

      table_header: ['Ticker', 'Tipo Ent.', 'Entrada', 'Soporte', 'Fecha de Soporte', 'Rentabilidad', 'Precio Inicial LT', 'Precio Final LT', 'Barra Inicial LT', 'Barra Final LT', 'Operaciones'],
      table_body: [],

      is_news_available: false,
      commentsPerPage: 10,
      comments_page: [],
      currentPage: 1,
      comments_length: 0,
    };
  },
  mounted() {
    this.stock_name = this._getStockName();

    var stock_interval = setInterval(() => {
      if (this.stock_name != "") {
        clearInterval(stock_interval);
        this._getStock(this.stock_name);
        this._getNews(this.stock_name);
      }
    }, 150);

    var dataInterval = setInterval(() => {
      if (this.stock != "") {
        clearInterval(dataInterval);
        this._getSimilarTickers();
        this._changeColorTextarea();
        if (this._g_IsUserLogged) {
          this._getPremiumParameters();
        }
      }
    }, 150);

    if (!this.is_mobile_device) {
      document.getElementById("chart-container").style.display = "flex";
    } else {
      document.getElementById("chart-container").style.height = "auto";
    }

  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_IsUserLogged"]),

    url_stock() {
      return process.env.VUE_APP_API + '/stockchart?t=23&s=' + this.$route.params.ticker;
    },
  },
  methods: {
    ...mapMutations(["setSelectedTicker"]),

    paginatedComments() {
      const startIndex = (this.currentPage - 1) * this.commentsPerPage;
      const endIndex = startIndex + this.commentsPerPage;
      this.comments_page = this.comments.slice(startIndex, endIndex);
      this.comments_length = Math.ceil(this.comments.length / 10);
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.paginatedComments();
      }
    },
    goToPage(index) {
      this.currentPage = index;
      this.paginatedComments();
      const element = document.getElementById("comments");
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    nextPage() {
      if (this.currentPage < this.totalPages()) {
        this.currentPage++;
        this.paginatedComments();
      }
    },
    totalPages() {
      return Math.ceil(this.comments.length / this.commentsPerPage);
    },
    _getInputValue(value) {
      this.new_comment = value[0]
    },
    _activeChart() {
      if (this.activeChart == false) {
        this.activeDescription = false;
        this.activeChart = true;
        var grt = document.getElementById("grafic-option");
        grt.classList.remove("info-button");
        var grr = document.getElementById("description-option");
        grr.classList.add("info-button");
      }
    },
    _activeDescription() {
      if (this.activeDescription == false) {
        this.activeChart = false;
        this.activeDescription = true;
        var gr = document.getElementById("description-option");
        gr.classList.remove("info-button");
        var gr2 = document.getElementById("grafic-option");
        gr2.classList.add("info-button");
      }
    },
    async _getStock(name) {
      let success = (response) => {
        if (this._g_IsUserLogged) {
          this.stock = response.data[0];
          this.stock_company = response.data[0].nom;
          this.stock_description = response.data[0].description;
          this.stock_index = response.data[0].index_valor;
          this.stock_actual_value = response.data[0].valor_actual;
          this.stock_divisa = response.data[0].divisa;
          this.stock_sector = response.data[0].sector;
          this.stock_nid = response.data[0].nid;
          this._getComments(response.data[0].nid);
          this.setSelectedTicker(response.data[0]);
        }
        else {
          this.stock = {};
          this.stock_company = response.data.nom;
          this.stock_description = response.data.description;
          this.stock_index = response.data.index_valor;
          this.stock_actual_value = parseFloat(response.data.valor_actual).toFixed(2);
          this.stock_divisa = response.data.divisa;
          this.stock_sector = response.data.gic_sector + '/' + response.data.gic_group;
          this.stock_nid = response.data.nid;
        }
      };

      let url = "/api/v1/stock-finder/stocks/symbol/" + name;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('207', 'Error cargando valor.');

      await this._getAPICall(url, successHandler, failureHandler);
    },
    _getStockName() {
      let name = this.$route.params.ticker;
      name = name.replace("_and_", "&");

      if (!this._g_IsUserLogged) {
        let ifr = document.getElementById("iframe");
        ifr.src += name;
      }

      return name;
    },
    async _getNews(ticker) {
      let success = (response) => {
        if (response.data == "") {
          this.is_news_available = false;
        } else {
          //this.is_news_available = true;
          var newsContainer = document.getElementById("news-container");
          var icon = document.getElementById("stock-img");
          icon.src = response.data.logo;
          var news = response.data.noticias;
          for (let i = 0; i < news.length; i++) {
            newsContainer.innerHTML +=
              '<div class="caja"><div class="container-img"><img class="container-img" src="' +
              news[i].img +
              '" width="140px"></div><div class="title-text"><a href="' +
              news[i].url_link +
              '">' +
              news[i].title +
              '</a><p class="news-descrition">' +
              news[i].text +
              '</p><p class="news-autor">' +
              news[i].web +
              "</p></div></div>";
          }
        }

      };

      let url = "/api/v1/stocks/news/" + ticker;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('208', 'Error cargando noticias.');

      await this._getAPICall(url, successHandler, failureHandler);
    },
    _getSimilarTickers() {
      let success = (response) => {

        var container = document.getElementById("similares");
        var stocks = response.data;
        var max = 3;
        for (let i = 0; i < stocks.length && max > 0; i++) {
          if (
            this.stock_name != stocks[i].ticker
          ) {
            container.innerHTML +=
              '<div "similar-ticker"><a class="similare" href=' + this.$detectEnvironment() + '/app/stock/' +
              stocks[i].stock.ticker +
              ">" +
              stocks[i].stock.ticker +
              " (" +
              stocks[i].stock.nom +
              ")</a></div><br>";
            max--;
          }
        }
      };

      let url = "/api/v1/stock-finder/similar-stocks/" + this.stock_name;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('209', 'Error buscando valores parecidos.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getComments(nid) {
      let success = (response) => {
        this.comments = response.data;
        this.paginatedComments();

        setTimeout(this._changeClassStyles, 50);
      };

      let url = "/api/v1/forum/comments/" + nid + "?all_comments=true";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('210', 'Error cargando comentarios del foro.');
      const apiCallConfig = {timeoutMs: 0};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },
    createComment: function () {
      var params = new URLSearchParams();
      params.append("new_comment", this.new_comment);

      let success = (response) => {
        if (response.data.status == "ok") {
          this.$vueOnToast.pop("success", "Comentario", "Enviado con exito!");
          this._getComments();
          setTimeout(this._changeClassStyles, 50);
        } else {
          this.$vueOnToast.pop(
            "error",
            "Comentario no creado",
            response.data.message
          );
        }
      };

      let url = "/api/v1/forum/comments/add/" + this.stock.nid;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('301', 'Error recuperando comentarios.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
    _changeClassStyles() {
      for (const textBold of document.getElementsByClassName("tthh-user")) {
        textBold.style.fontWeight = "bold";
        textBold.style.textTransform = "capitalize";
      }
      for (const dateRight of document.getElementsByClassName(
        "tthh-user-date-comment"
      )) {
        dateRight.style.float = "right";
      }
    },
    _changeColorTextarea() {

      let inputComment = document.getElementById("input_comment");

      inputComment.addEventListener('click', function () {

        for (let inputTextfieldComment of document.getElementsByClassName("comments_input")) {
          inputTextfieldComment.style.backgroundColor = "#EDF8FB";
        }
        let iconColorComment = document.getElementById("icon_button_comment")
        iconColorComment.style.color = "#FFFFFF";

        let buttonColorComment = document.getElementById("comment-button-id")
        buttonColorComment.style.backgroundColor = "#00AEDD"
      });

    },
    _getPremiumParameters() {
      let success = (response) => {
        let parameters = response.data
        let obj = {}
        obj["a"] = parameters[0].ticker;
        obj["b"] = parameters[0].entry_type_name;
        obj["c"] = parameters[0].real_entry.toFixed(2);
        obj["d"] = parameters[0].soport.toFixed(2);
        obj["e"] = parameters[0].data_soport;
        obj["f"] = parameters[0].tae_lt.toFixed(1) + "%";
        obj["g"] = parseInt(parameters[0].lt_ini).toFixed(2);
        obj["h"] = parseInt(parameters[0].lt_fi).toFixed(2);
        obj["i"] = parameters[0].lt_pos_ini;
        obj["j"] = parameters[0].lt_pos_fi;
        obj["k"] = "gráfico";
        this.chart_url = "/app/graficador-dinamico/ticker/" + parameters[0].ticker.replace("&", "_and_");
        this.table_body.push(obj)
      };

      let url = "/api/v1/stock-finder/stocks/symbol/" + this.stock_name;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('211', 'Error valor.');

      this._getAPICall(url, successHandler, failureHandler);


    },
    upComment: function (comment) {
      this.voteComment(comment, 1);
      this.getComments(this.stock_nid);
    },
    downComment: function (comment) {
      this.voteComment(comment, -1);
      this.getComments(this.stock_nid);
    },
    voteComment: function (comment, value) {
      var params = new URLSearchParams();
      params.append('vote_value', value);
      params.append('entity_id', comment.comment_id);

      let success = () => {
        this.$vueOnToast.pop('success', "Comentario", "Votado con exito");
        this.getComments(this.stock_nid);
      }

      let url = '/api/v1/forum/comments/vote/' + this.stock.nid

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('302', 'Error votando comentarios.');

      this._postAPICall(url, params, successHandler, failureHandler);
    }
  },
};
</script>

<style lang="scss">
$color_dark: #000010;
$back_grey: #cdd1df;

#stock-img {
  margin: auto;
  vertical-align: middle;
}

.stocks-title {
  margin: 0;
  padding: 60px 5px 40px;
  color: $color_dark;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  width: 100%;
}

.sector-content {
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: space-between;
}


.news-title {
  margin-top: 215px;
}

.noticias {
  display: flex;
}

.container-img {
  border: 1px solid transparent;
  border-radius: 10px;
}

.caja {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  background: transparent;
  margin: 0 0 15px;
  overflow: hidden;
  padding: 8px;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border-bottom: 1px solid black;
  display: flex;
}

.title-text {
  cursor: pointer;
  color: black;
  font-size: 17px;
  font-weight: 400;
  text-align: justify;
  margin-left: 5px;
  margin-top: 2px;
}

a {
  color: black;
  text-decoration: none;
}

.news-descrition {
  font-size: 14px;
  color: #444242;
}

.news-autor {
  font-size: 12px;
  color: #444242;
}

.titulito {
  font-size: 17px;
}

.similare {
  color: #00add9 !important;
}

#comment-user {
  font-size: 14px;
}

#comment-text,
.no-comment-text {
  letter-spacing: 0.4px;
  margin-top: 20px;
  flex-grow: 1;
  margin-left: 20px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 22px;
}

#comment-rating {
  display: flex;
  justify-content: end;
  align-items: center;
}

.comment-icon {
  margin: 0 2px;
  cursor: pointer;
}

.comment-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-left: 2px;
  margin-left: 20px;
}

.text-bold {
  font-weight: bold;
}

.user-box {
  align-items: center;
  margin-bottom: 15px;
  line-height: 100%;
  width: 150px;
  min-width: 150px;
}

#comment {
  margin: 0 20px;
}

.comments {
  margin-top: 65px;
}

.comment-icon-user {
  font-size: 40px;
  margin-right: 15px;
}

.comment-box {
  display: flex;
  align-items: center;
  margin: 30px 0px;
  margin-bottom: 30px;
}

.comment-count-text {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  margin: 10px 0 20px;
}

.mdc-text-field__input {
  height: 10px !important;
  margin-bottom: 30px !important;
}

#input_comment {
  flex-grow: 1;
}

.info-button {
  width: 200px;
  box-shadow: none !important;
  border: none !important;
  color: #00ADD9 !important;
  border-radius: 10px !important;
  cursor: pointer;
}

.info-button:hover {
  background-color: #ebebeb !important;
}

.description {
  text-align: justify;
  background-color: #FFF;
  color: #777;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.iframe-container {
  flex-grow: 1;
}

.member-since {
  margin-top: 10%;
  font-size: 10px;
  text-align: center;
}

.user-picture {
  height: 100px;
  text-align: center;
}

.tthh-user {
  display: table;
  margin: 0 auto;
  margin-top: 10px;
}

.tthh-user-date-comment {
  display: table;
  margin: 0 auto;
  float: none !important;
  margin-top: 10px;
}

.forum-heading {
  text-align: center;
  margin-bottom: 10px;
}

.info-parameter.mobile {
  margin-left: 15px;
}

#chart-container {
  display: flex;
}

@media (max-width: 600px) {
  #chart-container {
    display: block !important;
  }
}

.no_visible {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  background: none;
  border: none;
  color: #007bff;
  padding: 0;
  margin: 0 2px;
  cursor: pointer;
  font-size: 14px;
}

.pagination button:hover {
  text-decoration: underline;
}

.pagination button.active {
  font-weight: bold;
}

.workspace-css {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
}


</style>
