<template>
  <div class="input-container-size">
    <label :class="input_key_to_textarea_values" class="mdc-text-field mdc-text-field--outlined mdc-text-field--textarea mdc-text-field--no-label">
      <span class="mdc-notched-outline">
        <span class="mdc-notched-outline__leading"></span>
        <span class="mdc-notched-outline__trailing"></span>
      </span>
      <span class="mdc-text-field__resizer">
        <textarea class="mdc-text-field__input" rows="8" cols="40" aria-label="Label" :placeholder="input_placeholder"></textarea>
      </span>
    </label>
  </div>
</template>




<script>
  import { MDCTextField } from '@material/textfield';

  export default {
    name: 'InputTextarea',
    data() {
      return {
        textfield: {},
      };
    },
    props: {
      input_placeholder: { type: String },
      input_key_to_textarea_values: { type: String },
    },
    mounted() {
      this.textfield = new MDCTextField(document.querySelector('.mdc-text-field' + '.' + this.input_key_to_textarea_values));
      
      this.textfield.foundation.handleInput = () => {
        this.handleEmit();
      };
    },
    methods: {
        handleEmit() {
          this.$emit('_emit_method_order_input_textarea', [this.textfield.value, this.input_key_to_textarea_values]);
        },
     }
  }
</script>


<style lang="scss" scoped>
    @use "@material/floating-label/mdc-floating-label";
    @use "@material/line-ripple/mdc-line-ripple";
    @use "@material/notched-outline/mdc-notched-outline";
    @use "@material/textfield";

    @include textfield.core-styles;

    .profile .mdc-text-field	{
      @include textfield.outlined-height(34px);
      width: 85%;
    }
    .profile .mdc-text-field.mdc-text-field--disabled {
      width: 90%;
    }

    .error-report .mdc-text-field	{
      @include textfield.outlined-height(150px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;
      text-decoration: none;

      textarea {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        text-decoration: none;
        resize: none;
      }

    }
    .error-report .input-container-size {
      width: 100%;
    }

    #notas .mdc-text-field {
      @include textfield.disabled-fill-color(#F3F4F7);
      @include textfield.ink-color(var(--color-text-primary));
      @include textfield.label-color(var(--color-text-primary)); //color del label que esta en el input y que luego se pone arriba
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.disabled-ink-color(var(--color-text-primary));
      @include textfield.outline-color(var(--color-text-primary));//color del borde cuando no esta seleccionado
      --mdc-theme-primary: #0E5F85FF; //con esta linea cambiamos el borde del input cuando esta seleccionado.
    }

    #stocks .mdc-text-field {
      @include textfield.outlined-height(70px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;

      textarea {
        resize: none;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: var(--color-text-primary) !important;
      border: 1px solid var(--color-text-primary) !important;
      background-color: var(--color-surface) !important;
      margin: 0;
      border-radius: 5px;
      padding-top:10px;
    }

    #stocks .input-container-size {
      width: 100%;
    }
    #stock-comments .mdc-text-field {
      @include textfield.outlined-height(70px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;

      textarea {
        resize: none;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }

    #stock-comments .input-container-size {
      width: 100%;
    }

    textarea {
      resize: none;
    }
    
    .mdc-text-field-character-counter {
    margin: 0px;}

    .mdc-text-field-helper-line.red {
    padding: 0;}

    .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.6);}

    .mdc-text-field {
    padding: 0 0 0 15px;}

    .mdc-text-field--outlined .mdc-text-field__resizer {
      resize: none;
    }

    @media (max-width: 599px){
    .mdc-text-field	 {
        width: 100%;
        min-width: 100px;}
    }

    @media (min-width: 600px) and (max-width: 839px) {
    .mdc-text-field	 {
        width: 100%;
        min-width: 180px;}
    }

    @media (min-width: 840px){
    .mdc-text-field	 {
        width: 100%;
        min-width: 100px;}
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
       -moz-appearance:textfield;
    }

    #registro .mdc-text-field {
      @include textfield.outlined-height(100px);
      @include textfield.placeholder-color(var(--color-text-primary));
      @include textfield.fill-color(var(--color-text-primary));
      border-radius: 5px;
      padding: 0;

      textarea {
        resize: none;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }

    #registro .input-container-size {
      width: 100%;
    }
</style>
