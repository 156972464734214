<template>
  <div class="main-container-custom-valores">
    <div class="table-custom table-custom">
      <div>
        <table>
          <thead class="top-table">
            <tr>
              <th v-for="(item, index) in table_header" :key="index" role="columnheader" data-column-id="date" scope="col"
                class="header-table">{{ item }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in table_body" :key="index">
              <th v-for="(cel, key) in row" :key="key" scope="row" class="table-row">
                <span v-if="_g_User.is_pre_eureker_user && cel === '---'" class="mdc-list-item__text typo-body-2">
                  <span @click="preEurekersWarning()" class="material-icons-outlined">visibility</span>
                </span>
                <span v-else-if="key == 'k'">
                  <div class="utility-container">
                    <div class="centered">
                      <a :href="chart_url" title="Graficador dinámico"><strong style="font-size: 14px;margin-right: 7px;">GD</strong></a>
                    </div>
                    <span class="fav-container" title="Añadir a favoritos" @click="showFavorites()">
                      <span class="material-icons-outlined">
                        grade
                      </span>
                    </span>
                    <span class="fav-container" title="Añadir a cartera" @click="showWaiting()">
                      <span class="material-icons-outlined">
                        more_time
                      </span>
                    </span>
                    <span class="fav-container" title="Añadir a cartera" @click="showRegistro()">
                      <span class="material-icons-outlined">
                        edit
                      </span>
                    </span>
                  </div>
                </span>
                <span v-else>
                  {{ cel }}
                </span>
              </th>
            </tr>
            <div class="tools-container">
              <Workspaces class="workspace-css" ref="child" :stocks="[stock]" :stock="stock"
                :show_workspace="show_workspace" @_closeFavoriteWorkspacesWindow="_closeFavoriteWorkspacesWindow">
              </Workspaces>
              <WaitingValues ref="waiting" :stocks="[stock]" :stock="stock" :show_waiting="show_waiting"
                @_closeWaitingValueWindow="_closeWaitingValueWindow">
              </WaitingValues>
              <Registro @close_registro="_closeRegistro" :registro_open="registro_open" />
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>



<script>
import TableCustom from "@/components/helpers/Table_custom.vue";
import Vue from 'vue';
import { mapGetters } from "vuex";
import Workspaces from '@/components/stockchart/components/Workspaces.vue';
import WaitingValues from '@/components/stockchart/components/WaitingValuesAdd.vue';
import Registro from "@/components/stockchart/components/Registro_nota_stock.vue";

export default {
  name: 'TableCustom',
  props: {
    table_header: { type: Array },
    table_body: { type: Array },
    table_obj: { type: Object },
    stock: { type: Object },
    chart_url: { type: String }
  },
  components: {
    Workspaces,
    WaitingValues,
    Registro
  },
  data() {
    return {
      link_to_store: "",
      show_workspace: false,
      show_waiting: false,
      registro_open: false,
    };
  },
  computed: {
    ...mapGetters(["_g_User"]),
  },
  mounted() {
    this.link_to_store = this.$detectEnvironment() + '/app/store';
    const MyComponentConstructor = Vue.extend(TableCustom);
    const table = new MyComponentConstructor();
  },
  methods: {
    preEurekersWarning() {
      let toast = {
        type: 'info', body: 'Para visualizar valores, acceda al Graficador/Buscador o actualice su suscripción.', clickHandler: function () {
          window.location.replace(this.link_to_store);
        }
      };
      this.$vueOnToast.pop(toast);
    },
    showFavorites: function () {
      this.show_workspace = true;
    },
    _closeFavoriteWorkspacesWindow() {
      this.show_workspace = false;
    },
    showWaiting: function () {
      this.show_waiting = true;
    },
    _closeWaitingValueWindow() {
      this.show_waiting = false;
    },
    showRegistro: function () {
      this.registro_open = true;
    },
    _closeRegistro() {
      this.registro_open = false;
    }
  }
}
</script>



<style lang="scss">
.tools-container {
  position: absolute !important;
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
}

#workspace-favorites-fullscreen {
  position: absolute !important;
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
  top: auto !important;
  left: auto !important;
}

.utility-container {
  display: flex;
  justify-content: space-between;
  /* Distribuye los elementos horizontalmente */
  align-items: center;
  /* Centra los elementos verticalmente */
}

.centered {
  flex: 1;
  /* Expande este contenedor para centrar el enlace */
  display: flex;
  justify-content: center;
  /* Centra el enlace horizontalmente */
  align-items: center;
  /* Centra el enlace verticalmente */
}

.centered a {
  text-decoration: none;
}

.tools-container {}

.main-container-custom-valores {
  display: inline;
}

#registro {
  position: relative !important;
  left: 250% !important;
  top: 7px !important;
}

.addwaitings-workspace-container-style {
  left: 380% !important;
  top: -97px !important;
}

.favorites-workspace-container-style {
  left: 380% !important;
  top: -97px !important;
}

@media (max-width: 700px) {
  #registro {
    position: absolute !important;
    left: 0% !important;
    top: 0px !important;
  }

  .addwaitings-workspace-container-style {
    left: 0% !important;
    top: 0px !important;
  }

  .favorites-workspace-container-style {
    left: 0% !important;
    top: 0px !important;
  }
}


.fav-container {
  margin-right: 6px;
  cursor: pointer;
}

.table-info {
  margin: 20px;
}

#registro {
  position: absolute;
}

#stocks {
  .table-custom {
    height: auto;
    width: auto;
    margin-top: 25px;
    margin-bottom: 50px;
  }

  .header-table {
    margin-right: 50px !important;
  }
}

#valores {
  .table-custom {
    height: auto;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 50px;
  }

  table {
    border-spacing: 10px !important;
  }

  .header-table {
    margin-right: 50px !important;
  }
}

.header-table {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.top-table {
  width: 100%;
}

.table-custom {
  height: 280px;
  width: 417px;
  background-color: #F3F4F7;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.table-row {
  font-size: 12px;
  background-color: white;
  font-weight: normal;
}

#best-bullish-values {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#hot-values {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#best-sectors {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#portfolio-changes {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#twin-souls {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
  }

  .table-custom {
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#artificial-inteligence {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#waiting-values {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#open-positions {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#last-ipos {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }

  .table-custom {
    height: 280px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#best-bullish {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }

  .table-custom {
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#fundamental {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }

  .table-custom {
    width: 362px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}

#fundamental-mobile {
  .header-table {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }

  .table-custom {
    width: 332px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row {
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }

  .top-table {
    width: 100%;
  }
}
</style>
